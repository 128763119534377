/*globals jQuery*/
var SEARCH_TIMEOUT = 350;
var FIRST_CALL = null;
var INPUT_SELECTOR = '.tc-table-search-input';

module.exports = function($table, callback) {

    var tableSearchInitialized = 'data-table-search-initialized';
    if (!($table instanceof jQuery)) {
        $table = jQuery($table);
    }

    if ($table.attr(tableSearchInitialized)) {
        console.warn('Double init for Table #' + $table.attr('id') + ' detected!', FIRST_CALL.stack);
        return;
    }
    else {
        FIRST_CALL = new Error('Call stack');
    }
    // https://stackoverflow.com/a/1634841
    function removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');   
        if (urlparts.length >= 2) {
    
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);
    
            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {    
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }
    
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }
    // Find all sort links
    $table[0].querySelectorAll('a.sort-field').forEach(function (sortLink) {
        sortLink.addEventListener('click', function (event) {
            if (location.search) {
                var sortOrderQueryName = $table[0].getAttribute('data-sort-order-query-name');
                var sortFieldQueryName = $table[0].getAttribute('data-sort-field-query-name');

                event.preventDefault();

                var newUrl = location.search;
                newUrl = removeURLParameter(newUrl, sortOrderQueryName);
                newUrl = removeURLParameter(newUrl, sortFieldQueryName);
                var newUrl = newUrl.replace(/^&|&$/, '');
                if (!newUrl.startsWith('?')) {
                    newUrl = '?' + newUrl;
                }
                location.href = (
                    newUrl + 
                    '&' + sortFieldQueryName + '=' + sortLink.getAttribute('data-field-name') + 
                    '&' + sortOrderQueryName + '=' + sortLink.getAttribute('data-next-order')
                );
            }
        });
    });

    // https://stackoverflow.com/a/11654596/4438600
    function updateQueryString(key, value, url) {
        if (url == null) url = window.location.href;
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
            hash;
    
        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null)
                return url.replace(re, '$1' + key + "=" + value + '$2$3');
            else {
                hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                    url += '#' + hash[1];
                return url;
            }
        }
        else {
            if (typeof value !== 'undefined' && value !== null) {
                var separator = url.indexOf('?') !== -1 ? '&' : '?';
                hash = url.split('#');
                url = hash[0] + separator + key + '=' + value;
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                    url += '#' + hash[1];
                return url;
            }
            else
                return url;
        }
    }

    function bindEvents($input) {
        $input.each(function (index, elem) {
            var $elem = jQuery(elem);
            $elem.bind('input', function () {
                if ($elem.val() != $elem._lastVal) {
                    if (searchTimeout !== false) {
                        clearTimeout(searchTimeout);
                    }
                    searchTimeout = setTimeout(function() {
                        $elem._lastVal = $elem.val();
                        $elem.addClass('loading');
                        queryLogic().always(function () {
                            $elem.removeClass('loading');
                        });
                    }, SEARCH_TIMEOUT);
                }
            }).bind('keydown', function () {
                if (searchTimeout !== false) {
                    clearTimeout(searchTimeout);
                }
            });
        });
    }

    $table.find('[data-inplace-calendar]').each(function (index, elem){
        var $calendarElem = jQuery(elem);
        $calendarElem.datetimepicker({
            format: $calendarElem.data('inplace-calendar'),
            useCurrent: false,
            focusOnShow: false,
            keepInvalid: true,
            widgetParent: '#page-wrapper',
            widgetPositioning: {
                verical: 'top',
                horizontal: 'left'
            }
        }).on('dp.error', function (e) {
            console.log(e);
            e.preventDefault();
            return false;
        }).on('dp.show', function () {
            var elemPosition = $calendarElem[0].getBoundingClientRect();
            jQuery('.bootstrap-datetimepicker-widget').css({
                position: 'fixed',
                top: elemPosition.bottom,
                left: elemPosition.left
            });
        }).on('dp.change', function (e) {
            if (!e.date || e.date.isValid()) {
                e.currentTarget.form.submit();
                // queryLogic();
            } else {
                e.preventDefault();
                return false;
            }
        });
    });

    function updateTableUrlPassers() {
        var $passers = $table.find('[data-pass-tablebuilder-url]');
        $passers.each(function(index, passer) {
            var url = passer.getAttribute('data-url');
            passer.setAttribute('href', url.replace('[tablebuilder-url]', encodeURIComponent(location.href)));
        });
    }

    var searchRequest = null,
        searchTimeout = false,
        $input = $table.find(INPUT_SELECTOR),
        $searchIcon = $table.find('.tc-search-icon > i'),
        $tableBody = $table.find('tbody'),
        $tableFooter = $table.find('tfoot'),
        $form = (jQuery('#' + $table.data('form-id')||$input[0].form)),
        searchIconOriginalClass = $searchIcon.attr('class');

    var queryLogic = function () {
        var xhr = null,
            $newBody = null,
            $newFooter = null,
            $results = null;
        if (searchRequest) {
            searchRequest.abort();
        }
        $searchIcon.attr('class', 'fa fa-spinner fa-spin');
        // Serialized url used to make the AJAX request to update the table
        var filterFormSerializedUrl = $form.serialize() + '&_tc_table=' + $form.attr('id');

        if ($form[0].hasAttribute('data-auto-update-url')) {
            // Built data array used to update the local url
            var data = $form.serializeArray().concat([{name: '_tc_table', value: $form.attr('id')}]);
            
            var newSearch = location.search;
            // Remove existing values from the query string url
            for (var i = 0; i < data.length; i++) {
                var key = data[i].name;
                var value = data[i].value;
                newSearch = updateQueryString(key, undefined, newSearch);
            }

            // Append serialized url to the now cleaned search url
            // UpdateQueryString can't handle the appearance of the same key & value multiple times (it would just replace the value over and over again),
            // so we have to remove the key & value pairs from the URL in order to be able to just append the serialized form url entirely
            newSearch = newSearch + (newSearch.length === 0 ? '?' : '&') + filterFormSerializedUrl;

            // Replace current history state with the locally generated url
            history.replaceState({}, document.title, newSearch);
        }

        xhr = jQuery.get($form.attr('action'), filterFormSerializedUrl);
        searchRequest = xhr;
        return xhr.then(function (res) {
            if (searchRequest === xhr) {
                $results = jQuery(res);

                // Get new body and footer from the server response
                $newBody = $results.find('table.tc-table>tbody');
                $newFooter = $results.find('table.tc-table>tfoot');
                
                // Event that allows us to perform some cleanup tasks before the table's body DOM is replaced
                document.documentElement.dispatchEvent(new CustomEvent('before-table-update', {
                    detail: {
                        id: $table.find('table').attr('id'),
                    }
                }));
                // Replace current
                $tableBody.replaceWith($newBody);
                if (!$tableFooter || $tableFooter.length === 0) {
                    $newFooter.insertBefore($newBody);
                } else {
                    $tableFooter.replaceWith($newFooter);
                }
                $tableBody = $newBody;
                $tableFooter = $newFooter;

                if (callback) {
                    callback($results);
                }
                $searchIcon.attr('class', searchIconOriginalClass);
                // Event that allows us to perform some tasks after the table's body DOM has been replaced
                document.documentElement.dispatchEvent(new CustomEvent('table-update', {
                    detail: {
                        id: $table.find('table').attr('id'),
                    }
                }));
            }
        }).fail(function () {
            if (searchRequest === xhr) {
                $searchIcon.attr('class', searchIconOriginalClass);
            }
        }).then(function() {
            updateTableUrlPassers();
        });
    };

    bindEvents($input);
    updateTableUrlPassers();
    // Initial search
    var currentValue = $input.val();
    if (currentValue && location.search.indexOf($input[0].name + '=' + encodeURIComponent(currentValue)) === -1) {
        queryLogic();
    }

    $table.attr(tableSearchInitialized, true);
};
