/*globals jQuery, toastr*/
module.exports = (function () {
    'use strict';

    var Notify = {
        raw: function (type, message, title, options) {
            options = options || {};
            jQuery(function() {
                toastr.options = jQuery.extend({
                    'closeButton': true,
                    'debug': false,
                    'progressBar': true,
                    'preventDuplicates': false,
                    'positionClass': 'toast-top-right',
                    'onclick': null,
                    'showDuration': '400',
                    'hideDuration': '400',
                    'timeOut': '5000',
                    'extendedTimeOut': '1000',
                    'showEasing': 'swing',
                    'hideEasing': 'linear',
                    'showMethod': 'fadeIn',
                    'hideMethod': 'fadeOut'
                }, options);

                toastr[type](title, message);
            });
        }
    };

    Notify.success = function (message, title, options) {
        return this.raw('success', message, title, options);
    };

    Notify.info = function (message, title, options) {
        return this.raw('info', message, title, options);
    };

    Notify.warning = function (message, title, options) {
        return this.raw('warning', message, title, options);
    };

    Notify.error = function (message, title, options) {
        return this.raw('error', message, title, options);
    };

    return Notify;
}());
