module.exports = function() {
    var  REGISTERED = 'data-widget-registered';
    var listenForChange = function(picker, inputElement)
    {
        picker.on('dp.change', function(e)
        {
            // JQuery event
            $(inputElement).trigger('change', e);
            // Native event
            inputElement.dispatchEvent(new CustomEvent('change'));
        });
    };

    var getDateWidgetLocale = function() {
        return document.documentElement.lang || 'en';
    };

    window.__registerBoostrapDateWidgets = function() {
        var dateWidgetElements = document.querySelectorAll('[data-enable-datetime-widget]');
        for (var i = 0; i < dateWidgetElements.length; i++)
        {
            var widgetElement = dateWidgetElements[i];
            if (!widgetElement.hasAttribute(REGISTERED))
            {
                var picker = $(widgetElement).datetimepicker({format: 'YYYY-MM-DD HH:mm', showClose: true, useCurrent: widgetElement.hasAttribute('data-use-current'), locale: getDateWidgetLocale()});
                var inputElement = widgetElement.tagName.toLowerCase() == 'input' ? widgetElement : widgetElement.querySelector('input');
                listenForChange(picker, inputElement);
    
                widgetElement.setAttribute(REGISTERED, '');
                widgetElement.removeAttribute('data-enable-datetime-widget');
                if (inputElement.readOnly || inputElement.disabled) {
                    var addon = widgetElement.querySelector('.input-group-addon');
                    if (addon) {
                        addon.classList.add('is-disabled');
                    }
                }
            }
        }
    
        var dateWidgetElements = document.querySelectorAll('[data-enable-date-widget]');
        for (var i = 0; i < dateWidgetElements.length; i++)
        {
    
            var widgetElement = dateWidgetElements[i];
            if (!widgetElement.hasAttribute(REGISTERED))
            {
                var picker = $(widgetElement).datetimepicker({format: 'YYYY-MM-DD', showClose: true, useCurrent: widgetElement.hasAttribute('data-use-current'), locale: getDateWidgetLocale()});
                var inputElement = widgetElement.tagName.toLowerCase() == 'input' ? widgetElement : widgetElement.querySelector('input');
                listenForChange(picker, inputElement);
    
                widgetElement.setAttribute(REGISTERED, '');
                widgetElement.removeAttribute('data-enable-date-widget');
                if (inputElement.readOnly || inputElement.disabled) {
                    var addon = widgetElement.querySelector('.input-group-addon');
                    if (addon) {
                        addon.classList.add('is-disabled');
                    }
                }
            }
        }
    };

    window.__registerBoostrapDateWidgets();
};
