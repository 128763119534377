/*globals swal, jQuery */
module.exports = function (title, message, opts) {
    opts = opts || {};
    var defer = jQuery.Deferred();
    swal({
        title: title,
        text: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3CBD33',
        html: true,
        confirmButtonText: opts.confirmButtonText || 'Yes',
        cancelButtonText: opts.cancelButtonText || 'No',
        closeOnConfirm: opts.closeOnConfirm || true,
        closeOnCancel: opts.closeOnCancel || true
    }, function (confirmed) {
        if (confirmed) {
            defer.resolve();
        } else {
            defer.reject();
        }
    });
    return defer.promise();
};
