window.TCApi = require('./libs/tcapi');
window.BluugoBrowser = require('./libs/bbclient');
window.Notify = require('./libs/notify');
window.Confirm = require('./libs/confirm');
window.TableSearch = require('./libs/table-search');
window.TableCheckboxes = require('./libs/table-checkboxes');
window.ChartExtensions = require('./libs/chartjs-extensions');
window.DateTimeFieldHelpers = require('./libs/datetime-field-helpers');

// Handle flash messages via django.contrib.messages
var handleFlashMessages = require('./libs/flash-messages');
handleFlashMessages();

$('.dropdown-menu.no-autosubmit input, .dropdown-menu.no-autosubmit label').click(function(e) {
    e.stopPropagation();
});

document.addEventListener("DOMContentLoaded", function() {
    DateTimeFieldHelpers();
});
