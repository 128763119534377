/*globals module*/
module.exports = (function () {
    'use strict';

    function Plugin(client, cid, name, info) {
        this.api = client;
        this.cid = cid || null;
        this.name = name;
        this.info = info || {};
        this.listeners = {};
    }

    Plugin.prototype.isAvailable = function () {
        return this.cid !== null;
    };

    Plugin.prototype.call = function (method, args) {
        if (!this.isAvailable()) {
            throw new Error('Unable to call plugin ' + this.name);
        }
        return this.api.cmd('run', {plugin: this.name, cid: this.cid, cmd: method, args: args});
    };

    Plugin.prototype.on = function (event, callback, context) {
        if (!this.listeners.hasOwnProperty(event)) {
            this.listeners[event] = [];
        }
        this.listeners[event].push({cb: callback, context: (context || this) });
    };

    Plugin.prototype.off = function (event, callback) {
        if (this.listeners.hasOwnProperty(event)) {
            if (!callback) {
                this.listeners[event].length = 0;
            } else {
                var index = -1;
                this.listeners.some(function (listener, idx) {
                    if (listener.cb === callback) {
                        index = idx;
                        return true;
                    }
                });
                if (index >= 0) {
                    this.listeners.splice(index, 1);
                }
            }
        }
    };

    Plugin.prototype.trigger = function (event, args) {
        if (this.listeners.hasOwnProperty(event)) {
            this.listeners[event].forEach(function (listener) {
                listener.cb.apply(listener.context, args);
            });
        }
    };

    Plugin.prototype.unplug = function () {
        delete this.client.plugins[this.cid];
        this.listeners = {};
        this.api.cmd('unplug', {cid: this.cid, name: name});
    };

    function BB() {
        var info = null;
        this.plugins = {};
        this.info = {versionName: 'N/A', versionNumber: -1 };
        this.client = window.hasOwnProperty('__bb__') ? window.__bb__ : null;
        info = this.cmd('activate');
        if (info.success) {
            this.info = info.results;
            window.addEventListener('unload', function () {
                this.cmd('reset');
            }.bind(this));
        }
    }

    // Global constants
    BB.prototype.STATE_NFC_OFF = 1;
    BB.prototype.STATE_NFC_TURNING_ON = 2;
    BB.prototype.STATE_NFC_ON = 3;
    BB.prototype.STATE_NFC_TURNING_OFF = 4;
    BB.prototype.BUTTON_FN1 = 21;
    BB.prototype.BUTTON_FN2 = 22;
    BB.prototype.BUTTON_FN3 = 23;

    BB.prototype.isAvailable = function () {
        return this.client !== null;
    };

    BB.prototype.cmd = function (name, args) {
        if (this.client) {
            var strResult = '';
            if (this.client) {
                strResult = this.client.cmd(JSON.stringify({
                    cmd: name,
                    args: args || null
                }));
            }
            return JSON.parse(strResult) || null;
        } else {
            return { success: false, error: 'BB not available' };
        }
    };

    BB.prototype._trigger = function (specs) {
        var event = specs.event,
            args = specs.args,
            name = null;
        if (specs.cids) {
            specs.cids.forEach(function (cid) {
                if (this.plugins.hasOwnProperty(cid)) {
                    this.plugins[cid].trigger(event, args);
                }
            }, this);
        } else {
            for (name in this.plugins) {
                if (this.plugins.hasOwnProperty(name)) {
                    this.plugins[name].trigger(event, args);
                }
            }
        }
    };

    BB.prototype.plugin = function (name) {
        var info = this.cmd('plugin', {name: name}),
            plugin = null;
        if (info.success) {
            plugin = new Plugin(
                this,
                info.results.cid,
                info.results.name,
                info.results.info
            );
            this.plugins[plugin.cid] = plugin;
            return plugin;
        }
        return new Plugin(this, null, name);
    };
    return new BB();
}());
