/*globals $, Notify */
module.exports = function () {
    var msg,
        fn,
        options;
    $(function () {
        // Messages are hidden in a <script>-tag in document head
        $('script[data-flash]').each(function () {
            msg = $(this);
            switch (msg.data('flash')) {
            case 'debug':
            case 'info':
                fn = Notify.info;
                break;
            case 'success':
                fn = Notify.success;
                break;
            case 'warning':
                fn = Notify.warning;
                // Persistent errors and warnings
                options = {timeOut: 0};
                break;
            case 'error':
                fn = Notify.error;
                // Persistent errors and warnings
                options = {timeOut: 0};
                break;
            default:
                fn = null;
            }
            if (fn) {
                fn.call(
                    Notify, 
                    msg.text(), 
                    undefined, // No title (there has never been a title)
                    options
                );
            }
        });
    });
};
