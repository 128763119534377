/*globals jQuery, module*/
(function () {
    'use strict';

    function TCApi() {}

    TCApi.prototype.call = function (method, args, publicApi) {
        var defer = jQuery.Deferred(),
            result = null,
            data = JSON.stringify({ method: method, args: args }),
            url = (publicApi ? '/internal/api/public/' : '/internal/api/private/') + '?_=' + new Date().getTime();

        jQuery.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: data,
            xhrFields: {
                withCredentials: true
            }
        }).then(function (result) {
            defer.resolve(result);
        }, function (e) {
            if (e.responseJSON) {
                result = e.responseJSON;
            } else {
                result = {
                    code: e.status,
                    error: e.statusText
                };
            }
            result.xhr = e;
            defer.reject(result);
        });

        return defer.promise();
    };

    module.exports = new TCApi();
}());
