/*globals jQuery*/

module.exports = function(tableElement, callback) {
    var checkboxColumns = tableElement.querySelectorAll('input[type="checkbox"].checkbox-column-header');

    var checkboxHeader;
    for(var i = 0; i < checkboxColumns.length; i++) {    
        checkboxHeader = checkboxColumns[i];
        var name = checkboxHeader.getAttribute('data-target');

        var targetCheckboxes = tableElement.querySelectorAll('input[type="checkbox"][name="' + name +'"].checkbox-column');
        
        function applyEvent(header, checkbox, allCheckboxes) {
            header.addEventListener('click', function(e) {
                checkbox.checked = header.checked;
            });

            checkbox.addEventListener('click', function(e) {
                var checkedCount = 0;
                for(var k = 0; k < allCheckboxes.length; k++) {
                    checkedCount += allCheckboxes[k].checked ? 1 : 0;
                }

                header.checked = checkedCount == allCheckboxes.length;
            })
        };

        for(var j = 0; j < targetCheckboxes.length; j++) {
            applyEvent(checkboxHeader, targetCheckboxes[j], targetCheckboxes);
        }
    }
};
