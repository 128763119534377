/*globals jQuery, module*/
(function () {
    'use strict';

    function ChartExtensions() {}

    ChartExtensions.prototype.createDatasetToggles = function(elementId, chart)
    {
        var chartCanvas = document.getElementById(elementId);

        var datasetTogglesElement = document.createElement('div');
        datasetTogglesElement.className = 'chart-dataset-toggles';

        for (var key in chart.data.datasets)
        {
            var dataset = chart.data.datasets[key];

            var checkboxWrapper = document.createElement('span');
            checkboxWrapper.className = 'dataset-toggle';
            
            checkboxWrapper.innerHTML = 
            '<label>' +
                '<input type="checkbox" checked>' +
                '<span class="text" style="border-color: ' + dataset.backgroundColor + ';">' + dataset.label + '</span>' +
            '</label>';
            
            var checkbox = checkboxWrapper.querySelector('input[type=checkbox]');
            
            var getClickHandler = function(chart, dataset)
            {
                return function(e)
                {
                    dataset.hidden = !dataset.hidden;
                    chart.update();
                };
            };

            checkbox.addEventListener('click', getClickHandler(chart, dataset));
            datasetTogglesElement.appendChild(checkboxWrapper);
        }

        chartCanvas.parentNode.insertBefore(datasetTogglesElement, chartCanvas);
    }

    module.exports = new ChartExtensions();
}());
